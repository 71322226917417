<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-bella">
        <a href="http://bella-clean.ro/" target="_blank">
          <img src="@/assets/casestudy/bella-logo.svg" alt="Bella Clean"></a>
        <span class="sep"></span>
        <p>Bella Clean is the best cleaning service in Sibiu. Whether you are looking for
          residential cleaning, office or commercial cleaning, they can help you. With a large
          experience in cleaning domain they offer top quality and clean services. Their
          website was built to reflect that.</p>
      </div>
    </div>

    <div class="laptop-img bella-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/bella-preview.png" alt="Bella Clean">
      </div>
    </div>

    <div class="container-about bella-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/bella-diamond.svg" alt="Bella Clean">
        <h3>About the project</h3>
        <p>A clean presentation of what Bella Clean can offer to your home or office. They
          wanted a design and website that is clean and simple, where the user can view their list of
          services that include multiple type of locations, a list of pricing and contact info for
          customers based in Sibiu.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img src="@/assets/casestudy/opensans-font.svg" alt="Bella Clean Font">
            <h3><span style="color:#29abe2">Co</span><span style="color:#d1d1d1">lo</span><span
                style="color:#2b2b2b">rs</span></h3>

            <div class="colors">
              <div class="fcolor bc-p">Primary</div>
              <div class="scolor bc-s">Secondary</div>
              <div class="tcolor bc-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>

            <div class="color-codes">
              <div class="ccodes">
                <p class="bc-primary">#29abe2</p>
                <p class="bc-secondary">#2b2b2b</p>
                <p class="bc-third">#d1d1d1</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name:'Careful'}" class="left">
              <img src="@/assets/casestudy/btn-careful.jpg" alt="Careful">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'MaxConcept'}" class="right">
              <img src="@/assets/casestudy/max-related.png" alt="Max Inspire">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#29abe2"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";

export default {
  name: "BellaClean",
  components: {LetsChat, Footer, ContactUs}
}
</script>

<style lang="scss">
.logo-bella img {
  max-width: 320px;
}

.bella-laptop {
  background-color: #29abe2;
}
.bc-p {
  background-color: #29abe2;
}
.bc-primary {
  color: #29abe2;
}
.bc-s {
  background-color: #2b2b2b;
}
.bc-secondary {
  color: #2b2b2b;
}
.bc-t {
  background-color: #d1d1d1;
}
.bc-third {
  color: #d1d1d1;
}
.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #29abe2 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>